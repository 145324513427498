<template>
  <div class="container">
    <img src="@/assets/BerryThiefRecords-BlackWhite.png" alt="Logo" />
    <h1>Under Construction</h1>
    <h2>工事中</h2>
  </div>
</template>

<script>
export default {
  name: "UnderConstructionView",
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

img {
  max-width: 200px;
  margin-bottom: 20px;
}

h1 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

h2 {
  margin: 0;
  font-size: 20px;
  color: #666;
}
</style>
